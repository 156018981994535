import { HistoryLocation } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout/Layout';
import SiteMetadata from '../components/SiteMetadata/SiteMetadata';
import styles from './tag.module.css';
import PostCard from '../components/PostCard/PostCard';

export interface TagProps {
  location: HistoryLocation;
  data: TagData;
  pageContext: {
    tag: string;
  };
}

export interface TagData {
  site: {
    siteMetadata: {
      title: string;
      keywords: string[];
    };
  };
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          title: string;
          date: string;
          description: string;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          image: any;
        };
        fields: {
          slug: string;
          readingTime: {
            text: string;
          };
        };
        excerpt: string;
      };
    }[];
  };
}

const Tag: React.FC<TagProps> = (props) => {
  const siteMetadata = props.data.site.siteMetadata;
  const posts = props.data.allMarkdownRemark.edges;
  const tag = props.pageContext.tag;
  const title = `Tagged in: ${tag}`;

  return (
    <Layout title={siteMetadata.title} location={props.location}>
      <SiteMetadata
        title={title}
        keywords={siteMetadata.keywords}
        pathname={props.location.pathname}
      />
      <div className={styles.container}>
        <h1>{`Tagged in "${tag}"`}</h1>

        {posts.map(({ node }) => {
          const slug = node.fields.slug;
          const title = node.frontmatter.title || slug;
          const date = node.frontmatter.date;
          const description = node.frontmatter.description || node.excerpt;
          const readingTime = node.fields.readingTime.text;

          let image;
          if (node.frontmatter.image) {
            image = node.frontmatter.image.childImageSharp.fluid;
          }

          return (
            <PostCard
              key={slug}
              title={title}
              slug={slug}
              date={date}
              description={description}
              readingTime={readingTime}
              image={image}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TagsQuery($tag: String!) {
    site {
      siteMetadata {
        title
        keywords
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { eq: $tag } }
        fields: { draft: { eq: false } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            description
            image {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default Tag;
